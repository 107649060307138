import "./src/styles/global.css";
import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { amber } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2196f3",
      contrastText: "#fff",
    },
    secondary: amber,
  },
});

export function wrapRootElement({ element }) {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>;
}
