module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pilvikone","short_name":"Pilvikone","start_url":"/","background_color":"#323232","theme_color":"#323232","display":"standalone","icon":"src/assets/logo_square.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3321ff1a6f424c3f532e5c1f35025255"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
